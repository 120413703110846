import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { showToast, closeToast } from 'vant';
import Vuex from 'vuex';
import store from "@/store"
import './permission.js'
import Vant from "vant"; // 整体导入vant组件库
import "vant/lib/index.css"; // 引入vant样式表
import "amfe-flexible";
import "./assets/reset.css"
import VueClipboard from 'vue-clipboard2';
const app = createApp(App);
app.use(router);
app.use(Vant)
app.use(Vuex)
app.use(store)
app.use(VueClipboard)

app.config.globalProperties.$toast = showToast
app.config.globalProperties.$closeToast = closeToast

app.mount('#app');
