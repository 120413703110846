import { router } from './router'
import { showToast } from 'vant'
import { getCookie } from './utils/auth'

const whiteList = ['/login'] //登录后禁止进入的path

router.beforeEach((to, from, next) => {
	const hasToken = getCookie('token')
	if (hasToken) {
		if (whiteList.indexOf(to.path) !== -1) {
			next({ path: "/" })
		} else {
			next()
		}

	} else {
		if (to.meta.roles) {
			// let prs = "";
			// if (to.query.id) {
			// 	prs = `?id=${to.query.id}&title=${to.query.title}`
			// 	next(`/login?redirect=${to.path}${prs}`)
			// } else {
			// 	next(`/login?redirect=${to.path}`)

			// }
			let ps = ""
			if (to.query.id) {
				ps = `?id=${to.query.id}&title=${to.query.title}`
			}
			next(`/login?redirect=${to.path}${ps}`)

			showToast("请先登录!")
		} else {
			next()
		}
	}
})